import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { APP_NAME } from '@/common/Constants'

export default /*@__PURE__*/_defineComponent({
  __name: 'MainLayoutFooter',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("footer", null, " © " + _toDisplayString(_unref(APP_NAME)), 1 /* TEXT */))
}
}

})